@import '../../base.sass';

.market-selector {
  margin-left: 4px;
  margin-right: 4px;
  width: 100%;
  height: 100%;
  z-index: 20;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .current-sport-container {
    box-shadow: 0px 3px 6px #00000029;
    border: 1px solid #FFFFFF;
    border-radius: 2.5px;
    padding: 2px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    overflow: hidden;
    text-overflow: ellipsis;

    .current-sport {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      font-size: 12px;
      color: white; }

    .drop-down-icon {
      color: white; } }

  .sports-list {
    position: absolute;
    top: 44px;
    //height: 304px
    z-index: 30;
    font-size: 10px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    overflow-y: scroll;
    width: 210px;
    border-left: solid white 1px;
    border-bottom: solid white 1px;
    border-right: solid white 1px;
    border-top: solid white 1px;

    .sport-option-container {
      width: 100%;
      height: 30px;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;

      .sport-option {
        text-decoration: none;
        color: white;
        z-index: 32;
        width: 100%;
        height: 100%;

        &.selected {
          background: #313131; }

        .sport-details {
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          z-index: 35;
          font-size: 14px;
          padding-left: 10px; } } } } }
