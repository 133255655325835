
/* SASS COLOURS */

/* Xena Colours */

/* Black scheme */

$xenaColorHeader: #000000;
$xenaColorTwo: #313131;
$xenaColorDark: #121212;
$xenaColorDarkest: #000000;
$xenaColorWhite: #FFFFFF;
$xenaColorHighlight: #838DFF;
$xenaColorGradient: linear-gradient(270deg, #313131 0%, #464646 100%);

/* MISC GENERIC */

$backgroundColor: #F9F9FB;
$borderColor: #D0D0D0;
$white: #FFFFFF;
$red: #FF0000;
$black: #111;

/* SYSTEM */

$errorColor: red;
$successColor: #2e7d32;
$warningColor: yellow;

/* TRADE PAGE EVENTS **/

$priceColor: #0065DF;
$handicapBackground: #F1F1F1; //match paper 3
$new: #FFE500;
$newScore: #FF0000;
$decrease: indianred;
$increase: dodgerblue;
$removed: #FFE500;

$today: #3CA832;
$early: #399BFC;
$ir: #FF4646;
$favs: #FFE500;

$todayTint: lighten($today, 52.5%);
$earlyTint: lighten($early, 35%);
$irTint: lighten($ir, 32.5%);
$favsTint: lighten($favs, 47.5%);

$selectedCompetitionColor: rgba(0,0,0,.15);

/** BETSLIP **/

$usePmm: lighten($priceColor, 50%);
$maxPmm: lighten($priceColor, 37.5%);
$teamHighlightColor: yellow; //?
$equivColor: green;

/** BETS  AND ORDERS **/

$danger: #AA6688;
$success: #AAFFAA;
$placing: #FFFFAA;
$unplaced: #CCCCFF;
$unassigned: #CC88FF;

$failed: #FFAAAA;
$voids:  #d67680;

/** EVENT FEED **/

$away: indianred;
$home: dodgerblue;

/* POSITION */

$negative: red;
$positive: #2e7d32;
$impossible: #778;
