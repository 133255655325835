@import '../../../base.sass';

.betbar-order {

  //border-bottom: 1px solid $borderColor
  // flex-basis: 100vw
  // flex-grow: 1
  width: 90%;
  margin: 6px;
  margin-right: 6px;
  margin-left: 0px;
  flex-shrink: 0;
  height: 130px;
  display: grid;
  scroll-snap-align: start;
  grid-template-columns: 1fr 50px;
  grid-template-rows: 40px 90px;

  a {
    text-decoration: none; }

  .bet-status-container {
    grid-column: 1/2;
    grid-row: 1/2;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .status {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      font-size: 16px;
      font-weight: bold;
      margin-left: 10px;

      .order-icon {
        height: 20px;
        width: 20px;
        padding-right: 2px; }

      .order-icon-cancel {
        height: 20px;
        width: 20px;
        padding-right: 2px;
        padding-top: 6px; }

      .status-description {} }

    .position {
      margin-right: 10px;
      font-size: 10px;
      border: solid 1px #838DFF;
      padding: 5px;
      border-radius: 1px;
      background-color: #838DFF; } }

  .bet-info-container {
    grid-column: 1/3;
    grid-row: 2/3;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;
    padding-left: 10px;
    padding-bottom: 5px;

    .sport-container {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-end;
      font-size: 12px;
      height: 20px; }

    .contenders-container {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      font-size: 15px;
      height: 20px;
      overflow: hidden;

      .home {}

      .away {
        margin-left: 5px; } }

    .bet-description-container {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      font-size: 14px;
      font-weight: bold; }

    .stake-price-container {
      width: 95%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      font-size: 14px;
      font-weight: bold;
      height: 20px;

      .got-container {
        max-width: 65%;
        overflow: hidden;

        .bookie {
          padding-left: 3px; } }

      .view-order {
        font-size: 12px;

        .arrow-icon {
          margin: 0px;
          padding: 0px; } } } }

  .action-container {
    grid-column: 2/3;
    grid-row: 1/2;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding-top: 4px; }

  .body {
    width: 100%;

    .bets {
      margin-left: 0px !important; } }

  .betbar-status {
    box-sizing: border-box;
    white-space: nowrap;
    transition: width 1s;
    min-width: 4em;
    overflow: hidden;
    text-align: center;
    font-size: $smallFontSize;
    padding: 0 .25em;
    border-width: 1px 0 1px 1px;
    border-style: solid;
    border-color: $borderColor;

    &:last-child {
      border-right-width: 1px; }

    &.danger-stake {
      background-color: $danger;
      color: $white; }

    &.success-stake {
      background-color: $success; }

    &.unplaced-stake {
      background-color: $unplaced; }

    &.inprogress-stake {
      background-color: $placing; }

    &.shell {
      background-color: $warningColor; }

    &.open {
      .betbar-status.unplaced-stake {
        background-color: $unassigned; } }

    &.closed {
      .betbar-status.unplaced-stake {
        background-color: $unplaced; } }

    //special computed bet statuses
    &.bets-unknown {
      background-color: lighten($danger, 35%); }

    &.bets-inprogress {
      background-color: lighten($placing, 8%); } } }
