@import '../../../base.sass';

.smart-bar-betbar-order {

  @include alternating-rows;

  display: flex;
  overflow: auto;
  width: 95%;
  position: relative;

  &.narrow {
    .body {
      flex-flow: column;
      width: 100%;

      .info,
      .bets {
        width: 100%;
        padding: 0; }

      .bets {
        margin-left: .75em; }

      .info {
        display: block;
        padding: .25em 0;

        .home,
        .away {
          width: 50%;
          padding: 0;
          text-align: center;
          min-width: inherit;
          max-width: inherit; }

        .bet-type {
          min-width: 100%;
          display: block;
          padding: 0;
          text-align: center; }

        .price,
        .stake,
        .status {
          display: inline-flex;
          flex-grow: 1;
          min-width: 33%;
          justify-content: center;
          padding: 0; } } }

    .actions {
      position: absolute;
      right: 0;
      bottom: 0;
      top: .5em;
      width: 2em;
      min-width: 2em;
      display: flex;
      flex-flow: column;

      &.wide {
        min-width: 2em; }

      button {
        padding: 0;

        svg {
          font-size: 1em; } } } }

  .body {
    display: flex;
    flex-flow: row;
    flex-grow: 1;

    .bets {
      display: flex;
      width: 35%;
      box-sizing: border-box;
      padding: .4em .25em;
      text-decoration: none; }

    .info {
      display: flex;
      width: 65%;
      box-sizing: border-box;
      padding: .4em .25em;

      .id,
      .placer,
      .home,
      .away,
      .price,
      .stake,
      .status {
        max-width: 10em;
        min-width: 10em;
        padding-right: .5em;
        text-align: left;
        line-height: 1.5em; }

      .id {
        min-width: 7em;
        max-width: 7em;
        font-weight: bold;
        margin-right: .5em;

        .broker-icon {
          width: 1em;
          height: 1em;
          display: inline-block;
          margin-left: .5em;
          vertical-align: middle; }

        .extra-tag {
          font-size: $smallFontSize;
          font-style: italic;
          margin-left: .25em;
          font-weight: normal; } }

      .placer {
        max-width: 8em;
        min-width: 8em;
        text-align: center; }

      .bet-type {
        flex-grow: 1;
        text-align: left;
        overflow: hidden;
        display: inline-block;
        white-space: nowrap;
        text-overflow: ellipsis;
        padding-right: .5em;

        .sport {
          margin-right: .5em;
          line-height: 1.5em; }

        .description {
          font-weight: bold;
          line-height: 1.5em; } }


      .status {
        text-align: center;
        min-width: 6em;
        max-width: 6em;
        padding-right: 0;
        text-transform: capitalize;
        white-space: nowrap;

        img {
          height: 1em;
          width: 1em;
          vertical-align: text-top;
          margin-right: .5em; } }

      .expires {
        text-align: center;
        min-width: 12em;
        max-width: 12em;
        padding-right: .5em; }

      .price {
        padding-right: 1em; }

      .away,
      .home {
        overflow: hidden;
        display: inline-block;
        white-space: nowrap;
        text-overflow: ellipsis; }

      .price,
      .stake {
        padding-right: .5em;
        text-align: right;

        .wanted,
        .got {
          text-align: center; }

        .got {
          font-weight: bold; }

        .wanted {
          opacity: 0.25; } }

      .stake {
        min-width: 5em;
        max-width: 5em; }

      .price {
        min-width: 4em;
        max-width: 4em;
        text-align: center; } }

    &:hover {
      cursor: pointer; } }

  .actions {
    display: flex;
    min-width: 5em;

    &.wide {
      min-width: 7em; }

    button {
      box-sizing: content-box; } }

  .betbar-status {
    box-sizing: border-box;
    white-space: nowrap;
    min-width: 4em;
    overflow: hidden;
    text-align: center;
    font-size: $smallFontSize;
    padding: 0 .25em;
    border-width: 1px 0 1px 1px;
    border-style: solid;
    border-color: $borderColor;

    &.danger-stake {
      //background-color: $danger
      background-color: #C1505D; }

    &.success-stake {
      background-color: #24AC7D;
      color: white;
 }      //background-color: $success

    &.unplaced-stake {
      background-color: #858384;
      color: black;
 }      //$unplaced

    &.inprogress-stake {
      background-color: $placing;
      color: black;
 } }      //$placing

  &.shell {
    background-color: $warningColor; }

  &.open {
    .betbar-status.unplaced-stake {
      background-color: $unassigned;
      color: white; } }

  &.closed {
    .betbar-status.unplaced-stake {
      background-color: $unplaced; } }

  //special computed bet statuses
  &.bets-unknown {
    background-color: lighten($danger, 35%); }


  &.bets-inprogress {
    background-color: lighten($placing, 8%); }

  &.betbar.headless {
    .betbar-order {
      padding: .5em; } } }

