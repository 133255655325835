$fontFamily: Roboto, sans-serif;
$fontFamilyMoney: Borda, sans-serif;

$fontSize: 12px;
$navMenuFontSize: 14px;
$largeFontSize: 13px;
$smallFontSize: 11px;

$navMenuHeight: 4em;
$navMenuHeightNarrow: 3em;

$navMenuItemWidth: 8em;
$navMenuItemWidthNarrow: 3em;

$navMenuLogoWidth: 22em;
$navMenuLogoWidthNarrow: 5em;

$moneyHolderWidth: 2.5 * $navMenuItemWidth;
$moneyHolderWidthNarrow: 15em;

$titleHeight: 4em;

$drawerOffset: $titleHeight;

$viewPadding: 2em;
$sideWidth: 22rem;
$searchBarWidth: 32rem;

$sideClosedMargin: 0;
$sideOpenMargin: $sideClosedMargin + $sideWidth;
$betbarClosedHeight: 2.5em;

$sportSelectorHeight: 3.5em;
$tradePageGroupHeaderHeight: 4rem;

$fixedDraggableHolderWidth: 27em;

$tradeBaseWidth: 62.5;
$tradeBaseWidthAsian: 56.5;

$draggablePadding: 2;

$defaultMargin: 1rem;
$defaultViewPadding: 3em;

$defaultOfferGroupWidth: 12em;
$asianOfferGroupWidth: 8em;
$narrowOfferGroupWidth: 30em;

$defaultLineHeight: 1.5rem;
$asianLineHeight: 3 * $defaultLineHeight;
$asianLineHeightNoDraw: 2 * $defaultLineHeight;
$narrowLineHeight: 2em;
