@import 'colors.sass';
@import 'sizes.sass';

@mixin perf-button( ) {
  border: none;
  outline: none;
  background-color: transparent;
  color: #222;
  text-transform: uppercase;
  padding: 0;
  min-width: 1em;

  &:hover {
    cursor: pointer;
    background-color: rgba($borderColor,  .5); }

  svg {
    font-size: .75em; } }

@mixin admin-view( ) {
  .view-contents {
    display: flex;
    flex-direction: column; }

  .loading-holder {
    position: absolute;
    bottom: 0;
    width: 100%;
    margin: 0 auto;
    height: 4em;
    pointer-events: none;
    text-align: center;

    .generic-loader {
      margin: .5em auto; } }

  .action-buttons {
    padding: $defaultMargin/2 $defaultMargin;

    button {
      margin-right: .5em; }

    button:last-child {
      margin-right: 0 !important; } }

  .download-buttons {
    padding: $defaultMargin/2 $defaultMargin;

    button {
      height: 2em;
      line-height: 1em;
      margin-right: .5em;

      &:last-child {
        margin-right: 0; }

      .generic-loader {
        margin: 0;
        margin-right: .5em;

        img {
          width: 1em;
          height: 1em; } } } }

  .table-data {
    margin-left: $defaultMargin;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    position: relative;
    box-sizing: border-box;

    .check {
      min-width: 5em;
      max-width: 5em; }
    .id {
      min-width: 7em;
      max-width: 7em; }
    .username {
      min-width: 15em;
      max-width: 15em; }
    .name {
      min-width: 15em;
      max-width: 15em; }

    .username {
      .perms {
        margin-left: .5em;
        font-weight: bold;

        .can {
          color: $positive; }

        .cant {
          color: $negative;
          text-decoration: line-through; } } }

    .item-actions {
      text-align: center;
      padding: .25em 0;
      height: 2.5em;
      box-sizing: border-box;

      button {
        margin-right: .5em;
        height: 2em;
        padding: 0; }

      button:last-child {
        margin-right: 0 !important; } }

    .headers {
      padding: .5em 0;

      >span {
        display: table-cell;
        vertical-align: middle; } }

    .virtual-holder {
      position: absolute;
      top: 3.5em;
      bottom: 0;
      width: 100%; }

    .holder {
      border-bottom: 1px solid $borderColor;
      box-sizing: border-box;

      &:hover {
        background-color: $borderColor; }

      >div {
        height: 2.5em;

        >span {
          display: table-cell;
          vertical-align: middle;

          svg {
            font-size: 1em; } } }

      .customer.inactive {
        background-color: lighten($warningColor, 33%); }

      .group {
        .id,
        .username {
          line-height: 2.5em; }

        .username {
          max-width: 30em;
          min-width: 30em;
          font-weight: bold; } } } }

  &.narrow {
    .table-data {
      margin-left: 0;

      .id {
        min-width: 4em;
        max-width: 4em;
        padding-left: $defaultMargin; }

      .username,
      .name {
        min-width: 10em;
        max-width: 10em; } } } }

@mixin alternating-rows( ) {
  &:nth-child(even) {
    background-color: rgba(0,0,0,.05); } }

@mixin gpu-accelerated( ) {
  transform: translateZ(0); }

@mixin slide-down( ) {
  transition: height .25s, ease-out .25s; }

@mixin slide-up( ) {
  transition: max-height .25s, ease-out .25s; }

@mixin glowing() {
  transition: background-color 1s, ease-out 1s; }

@mixin blinking() {
  animation: blink-animation 1s steps(5, start) infinite;
  -webkit-animation: blink-animation 1s steps(5, start) infinite; }

@keyframes blink-animation {
  to {
    visibility: hidden; } }

@mixin glow-blink() {
  animation: blinker 1s steps(5, start) infinite;
  -webkit-animation: blinker 1s steps(5, start) infinite; }

@keyframes blinker {
  50% {
    opacity: 0.5; }
  100% {
    opacity: 1; } }
